<template>
  <div class="regis_form">
    <form @submit.prevent="register">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="name"
            :error-messages="UsernameError"
            solo
            background-color="#F3E5F5"
            flat
            hide-details="auto"
            label="Username"
            required
            class="mb-2"
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="email"
            :error-messages="emailErr"
            solo
            class="mb-2"
            hide-details="auto"
            background-color="#F3E5F5"
            flat
            type="email"
            label="Email"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="password"
            label="Password"
            :error-messages="PasswordError"
            hide-details="auto"
            class="mb-2"
            solo
            background-color="#F3E5F5"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            flat
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="passwordConfirm"
            label="Repeat Password"
            :error-messages="PwdConfirmError"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
            solo
            hide-details="auto"
            class="mb-2"
            background-color="#F3E5F5"
            flat
            @input="$v.passwordConfirm.$touch()"
            @blur="$v.passwordConfirm.$touch()"
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="button-group mt-5 d-flex flex-column">
        <v-btn class="mb-3" type="register" color="purple" v-if="!loading" dark>
          <b> REGISTER </b>
        </v-btn>

        <v-btn class="mb-3" color="purple" dark v-if="loading">
          <v-progress-circular
            :width="5"
            color="white"
            indeterminate
            class="mr-2"
          ></v-progress-circular>
          <b> LOADING ... </b>
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "Register",
  mixins: [validationMixin],
  validations: {
    name: { required },
    email: { required, email },
    password: { required, minLength: minLength(8) },
    passwordConfirm: { required, sameAsPassword: sameAs("password") }
  },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      show: false,
      show2: false,
      loading: false
    };
  },
  computed: {
    ...mapState({
      result: state => state.auth.solveMsg,
      errMsg: state => state.auth.errMsg,
      token: state => state.token
    }),
    UsernameError() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Nama Pengguna harus di isi!");
      return errors;
    },
    emailErr() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Email tidak valid!");
      !this.$v.email.required && errors.push("Email tidak boleh kosong!");
      return errors;
    },
    PasswordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Kata sandi minimal 8 karakter!");
      !this.$v.password.required && errors.push("Kata sandi harus di isi!");
      return errors;
    },
    PwdConfirmError() {
      const errors = [];
      if (!this.$v.passwordConfirm.$dirty) return errors;
      !this.$v.passwordConfirm.sameAsPassword &&
        errors.push("kata sandi tidak sama!");
      !this.$v.passwordConfirm.required &&
        errors.push("Kata sandi harus di isi!");
      return errors;
    }
  },
  methods: {
    register() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        this.$store
          .dispatch("auth/register", {
            username: this.name,
            email: this.email,
            password: this.password
          })
          .then(() => {
            this.$router.push(`/waiting/${this.email}`);
            this.alert("success", this.result.message);
            this.$emit("success");
            this.username = "";
            this.email = "";
            this.password = "";
            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
            for (let i in err) {
              this.alert("error", `${err[i][0]}`);
            }
          });
      }
    },

    alert(type, title, msg) {
      this.$notify({
        group: "authVerification",
        type: type,
        title: title,
        text: msg,
        duration: 8000
      });
    }
  }
};
</script>

<style scoped>
/* .regis_form {
  height: 300px;
  overflow: hidden;
  overflow-y: scroll;
} */
</style>
