<template>
  <v-row justify="start">
    <v-dialog
      v-model="dialogRegister"
      scrollable
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
      max-width="500px"
    >
      <v-card class="radius-card memphis">
        <v-toolbar class="bg_none" dense flat>
          <v-spacer></v-spacer>
          <v-btn fab x-small color="white" depressed @click="$emit('close')"
            ><v-icon small color="red">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2 white">
          <v-container>
            <div class="my-2">
              <h2 class="purple--text">Selamat Datang!</h2>
              <p>
                Silakan mengisi form yang sudah tersedia untuk memulai bergabung
                dengan kami.
              </p>
            </div>
            <form @submit.prevent="register">
              <v-row>
                <v-col cols="12" class="pa-2">
                  <v-text-field
                    v-model="fullname"
                    :error-messages="fullnameError"
                    solo
                    background-color="#F3E5F5"
                    flat
                    hide-details="auto"
                    label="Full Name"
                    required
                    @input="$v.fullname.$touch()"
                    @blur="$v.fullname.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" class="pa-3"> Jenis Kelamin </v-col>
                <v-col cols="12" md="8" class="pa-3">
                  <v-radio-group
                    v-model="jenis_kelamin"
                    :error-messages="genderError"
                    hide-details="auto"
                    dense
                    class="ma-0 pa-0"
                    row
                    @input="$v.jenis_kelamin.$touch()"
                    @blur="$v.jenis_kelamin.$touch()"
                  >
                    <v-radio label="Laki-laki" value="Laki-laki"></v-radio>
                    <v-radio label="Perempuan" value="Perempuan"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="pa-2">
                  <v-text-field
                    v-model="tempat_lahir"
                    :error-messages="tempatlahirError"
                    solo
                    background-color="#F3E5F5"
                    flat
                    hide-details="auto"
                    label="Tempat Lahir"
                    required
                    @input="$v.tempat_lahir.$touch()"
                    @blur="$v.tempat_lahir.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pa-2">
                  <v-menu
                    v-model="calendar"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="tanggal_lahir"
                        :error-messages="dateError"
                        label="Birth Date"
                        append-icon="mdi-calendar"
                        background-color="#F3E5F5"
                        readonly
                        flat
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        solo
                        @input="$v.tanggal_lahir.$touch()"
                        @blur="$v.tanggal_lahir.$touch()"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="tanggal_lahir"
                      @input="calendar = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="pa-2">
                  <v-text-field
                    v-model="no_hp"
                    label="No Hp"
                    :error-messages="nohpError"
                    hide-details="auto"
                    class="mb-2"
                    solo
                    background-color="#F3E5F5"
                    flat
                    @input="$v.no_hp.$touch()"
                    @blur="$v.no_hp.$touch()"
                  ></v-text-field>
                  <p class="small_txt red--text">{{ err_be.no_hp }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="pa-2">
                  <v-text-field
                    v-model="name"
                    label="Username"
                    :error-messages="UsernameError"
                    hide-details="auto"
                    class="mb-2"
                    solo
                    background-color="#F3E5F5"
                    flat
                    @input="$v.name.$touch()"
                    @blur="$v.name.$touch()"
                  ></v-text-field>
                  <p class="small_txt red--text">{{ err_be.username }}</p>
                </v-col>
                <v-col cols="12" md="6" class="pa-2">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    :error-messages="emailErr"
                    hide-details="auto"
                    class="mb-2"
                    solo
                    background-color="#F3E5F5"
                    flat
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  ></v-text-field>
                  <p class="small_txt red--text">{{ err_be.email }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="pa-2">
                  <v-text-field
                    v-model="password"
                    label="Password"
                    :error-messages="PasswordError"
                    hide-details="auto"
                    class="mb-2"
                    solo
                    background-color="#F3E5F5"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    flat
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                  ></v-text-field>
                  <p class="small_txt red--text">{{ err_be.password }}</p>
                </v-col>
                <v-col cols="12" md="6" class="pa-2">
                  <v-text-field
                    v-model="passwordConfirm"
                    label="Repeat Password"
                    :error-messages="PwdConfirmError"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    @click:append="show2 = !show2"
                    solo
                    hide-details="auto"
                    class="mb-2"
                    background-color="#F3E5F5"
                    flat
                    @input="$v.passwordConfirm.$touch()"
                    @blur="$v.passwordConfirm.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <div class="button-group mt-5 d-flex flex-column">
                <v-btn
                  class="mb-3"
                  type="register"
                  color="purple"
                  v-if="!loading"
                  dark
                >
                  <b> REGISTER </b>
                </v-btn>

                <v-btn class="mb-3" color="purple" dark v-if="loading">
                  <v-progress-circular
                    :width="5"
                    color="white"
                    indeterminate
                    class="mr-2"
                  ></v-progress-circular>
                  <b> Loading ... </b>
                </v-btn>
              </div>
            </form>
            <v-divider></v-divider>
            <div class="d-flex justify-center mt-2">
              <v-btn color="white" @click="checkAuth">
                <img
                  src="../../assets/img/google-logo.png"
                  class="mr-2"
                  height="20px"
                  alt=""
                />
                Sign in with google</v-btn
              >
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-3">
          <v-spacer> </v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";
export default {
  name: "trgis",
  mixins: [validationMixin],
  validations: {
    fullname: { required },
    jenis_kelamin: { required },
    tempat_lahir: { required },
    no_hp: { required },
    tanggal_lahir: { required },
    name: { required },
    email: { required, email },
    password: { required, minLength: minLength(8) },
    passwordConfirm: { required, sameAsPassword: sameAs("password") },
  },
  computed: {
    ...mapState({
      UsernameError() {
        const errors = [];
        if (!this.$v.name.$dirty) return errors;
        !this.$v.name.required && errors.push("Nama Pengguna harus di isi!");
        return errors;
      },
      fullnameError() {
        const errors = [];
        if (!this.$v.fullname.$dirty) return errors;
        !this.$v.fullname.required && errors.push("Nama Lengkap harus di isi!");
        return errors;
      },
      nohpError() {
        const errors = [];
        if (!this.$v.no_hp.$dirty) return errors;
        !this.$v.no_hp.required && errors.push("No Hp harus di isi!");
        return errors;
      },
      genderError() {
        const errors = [];
        if (!this.$v.jenis_kelamin.$dirty) return errors;
        !this.$v.jenis_kelamin.required &&
          errors.push("Jenis Kelamin harus di isi!");
        return errors;
      },
      tempatlahirError() {
        const errors = [];
        if (!this.$v.tempat_lahir.$dirty) return errors;
        !this.$v.tempat_lahir.required &&
          errors.push("Tempat lahir harus di isi!");
        return errors;
      },
      dateError() {
        const errors = [];
        if (!this.$v.tanggal_lahir.$dirty) return errors;
        !this.$v.tanggal_lahir.required &&
          errors.push("Tanggal lahir harus di isi!");
        return errors;
      },
      emailErr() {
        const errors = [];
        if (!this.$v.email.$dirty) return errors;
        !this.$v.email.email && errors.push("Email tidak valid!");
        !this.$v.email.required && errors.push("Email tidak boleh kosong!");
        return errors;
      },
      PasswordError() {
        const errors = [];
        if (!this.$v.password.$dirty) return errors;
        !this.$v.password.minLength &&
          errors.push("Kata sandi minimal 8 karakter!");
        !this.$v.password.required && errors.push("Kata sandi harus di isi!");
        return errors;
      },
      PwdConfirmError() {
        const errors = [];
        if (!this.$v.passwordConfirm.$dirty) return errors;
        !this.$v.passwordConfirm.sameAsPassword &&
          errors.push("kata sandi tidak sama!");
        !this.$v.passwordConfirm.required &&
          errors.push("Kata sandi harus di isi!");
        return errors;
      },
    }),
  },
  data() {
    return {
      fullname: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      no_hp: "",
      jenis_kelamin: "",
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      params: {
        client_id:
          "891149751164-fq0crtfico6kinqeg33uv9aqf3r0trrq.apps.googleusercontent.com",
      },
      show: false,
      show2: false,
      loading: false,
      calendar: false,
      err_be: {
        nama_lengkap: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        no_hp: "",
        jenis_kelamin: "",
        username: "",
        email: "",
        password: "",
      },
    };
  },
  props: ["dialogRegister"],

  methods: {
    register() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log(this.$v);
        return;
      } else {
        this.loading = true;
        this.$store
          .dispatch("auth/registerPublic", {
            username: this.name,
            email: this.email,
            password: this.password,
            tanggal_lahir: this.tanggal_lahir,
            tempat_lahir: this.tempat_lahir,
            no_hp: this.no_hp,
            nama_lengkap: this.fullname,
            jenis_kelamin: this.jenis_kelamin,
          })
          .then(() => {
            this.$router.push(`/waiting/${this.email}`);
            this.alert("success", this.result.message);
            this.$emit("success");
            this.username = "";
            this.email = "";
            this.password = "";
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            for (let i in err) {
              this.err_be[i] = err[i][0];
              this.alert("error", `${err[i][0]}`);
            }
          });
      }
    },
    async checkAuth() {
      const user = await this.$gAuth.signIn();
      const profile = user.getBasicProfile();
      let body = {
        email: profile.getEmail(),
        nama_lengkap: profile.getName(),
      };
      this.$store
        .dispatch("auth/publicGoogleSignup", body)
        .then(() => {
          this.$router.go();
        })
        .catch((err) => {
          this.alert("error", err.message);
        });
    },

    alert(type, title, msg) {
      this.$notify({
        group: "authVerification",
        type: type,
        title: title,
        text: msg,
        duration: 8000,
      });
    },
  },
};
</script>

<style scoped>
.memphis {
  background: url("../../assets/img/memphis.png");
  background-position: center;
  background-size: cover;
}
.bg_none {
  background: none !important;
}
.like_fild {
  background: #f3e5f5;
  border-radius: 4px;
  margin-left: 0px;
  margin-right: 0px;
}
</style>
