<template>
  <div class="_full-w d-flex align-center justify-center _bg-default _vh">
    <div class="temp-login white radius-card d-flex">
      <div :class="left ? 'disable' : 'enable'">
        <section class="d-flex justify-end pa-3">
          <router-link to="/">
            <v-icon color="purple"> mdi-home-circle </v-icon>
          </router-link>
        </section>
        <div class="d-flex flex-column align-center py-3 px-2">
          <section class="text-center">
            <h1 class="purple--text">Register</h1>
            <p class="grey--text">Masukan data Anda dengan benar</p>
          </section>
          <section class="temp-form">
            <Register />
            <div class="d-flex justify-center min_btm">
              <div class="bg_tx">atau</div>
            </div>
            <div class="mb-5">
              <v-divider></v-divider>
            </div>
            <div>
              <v-btn
                block
                dark
                color="purple"
                class="text-capitalize"
                @click.stop="dialogRegister = true"
                >Saya Bukan Psikolog</v-btn
              >
            </div>
            <v-divider class="mt-5"></v-divider>
            <div class="d-flex justify-center mt-2">
              <v-btn color="white" @click="checkAuth">
                <img
                  src="../../assets/img/google-logo.png"
                  class="mr-2"
                  height="20px"
                  alt=""
                />
                Sign in with google</v-btn
              >
            </div>
            <div class="switch mt-5">
              <v-divider></v-divider>
              <small class="mb-3">Sudah punya akun?</small> <br />
              <v-btn outlined color="purple" rounded @click="left = !left"
                >Login</v-btn
              >
            </div>
          </section>
        </div>
      </div>
      <div :class="left ? 'enable' : 'disable'">
        <section class="d-flex justify-end pa-3">
          <router-link to="/">
            <v-icon color="purple"> mdi-home-circle </v-icon>
          </router-link>
        </section>
        <div class="d-flex flex-column align-center py-5 px-2">
          <section class="text-center">
            <h1 class="purple--text">Login</h1>
            <p class="grey--text">Masukan data Anda dengan benar</p>
          </section>
          <section class="temp-form">
            <Login />
            <div class="switch mt-5">
              <v-divider></v-divider>
              <small class="mb-3">belum punya akun?</small> <br />
              <v-btn outlined color="purple" rounded @click="left = !left"
                >Register</v-btn
              >
            </div>
          </section>
        </div>
      </div>
      <div :class="left ? 'left ' : 'right'">
        <div v-if="left" class="d-flex flex-column align-center">
          <h2 class="white--text text-center">Selamat datang kembali!</h2>
          <p class="white--text text-center">
            Untuk terus terhubung dengan kami, silakan masuk dengan akun yang
            sudah terdaftar.
          </p>
          <div class="mt-5 d-flex flex-column align-center">
            <small class="white--text">Belum mempunyai akun?</small>
            <div>
              <button class="btn-outline" @click="left = !left">
                Register
              </button>
            </div>
          </div>
        </div>

        <div v-if="!left" class="d-flex flex-column align-center">
          <h1 class="white--text">Selamat datang!</h1>
          <p class="white--text text-center">
            Silakan mengisi form yang sudah tersedia untuk memulai bergabung
            dengan kami.
          </p>
          <div class="mt-5 d-flex flex-column align-center">
            <small class="white--text">Sudah mempunyai akun?</small>
            <div>
              <button class="btn-outline" @click="left = !left">Login</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <DialogRegister
        v-bind:dialogRegister="dialogRegister"
        @close="dialogRegister = false"
      />
    </div>
  </div>
</template>

<script>
import DialogRegister from "../../components/Auth/dialogRegister.vue";
import Login from "./Login.vue";
// import GoogleLogin from "vue-google-login";
import Register from "./Register.vue";
export default {
  data() {
    return {
      left: true,
      user_pblc: true,
      dialogRegister: false,
      params: {
        client_id:
          "891149751164-fq0crtfico6kinqeg33uv9aqf3r0trrq.apps.googleusercontent.com",
      },
    };
  },
  components: {
    Register,
    Login,
    DialogRegister,
    // GoogleLogin,
  },
  mounted() {
    if (this.$route.params.route == "login") {
      this.left = true;
    } else this.left = false;
  },
  methods: {
    async checkAuth() {
      const user = await this.$gAuth.signIn();
      const profile = user.getBasicProfile();
      let body = {
        email: profile.getEmail(),
        nama_lengkap: profile.getName(),
      };
      this.$store
        .dispatch("auth/userGoogleSignup", body)
        .then(() => {
          this.$router.go();
        })
        .catch((err) => {
          this.alert("error", err.message);
        });
    },
    swipe() {
      this.left = false;
    },

    alert(type, title, msg) {
      this.$notify({
        group: "authVerification",
        type: type,
        title: title,
        text: msg,
        duration: 8000,
      });
    },
  },
};
</script>

<style scoped>
.switch {
  display: none;
}
.temp-form {
  width: 70%;
}
.full_w {
  width: 100%;
}
.full-h {
  height: 100%;
}
._vh {
  height: 100vh;
}
.enable {
  width: 60%;
  transition: 0.5s;
}
.disable {
  width: 40%;
  transition: 0.5s;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  background: url("../../assets/img/memphis.png");
  background-position: center;
  background-size: cover;
  min-height: 100%;
  width: 40%;
  animation: rightmove 0.5s;
  right: 0;
}
.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  background: url("../../assets/img/memphis.png");
  background-position: center;
  background-size: cover;
  min-height: 100%;
  animation: leftmove 0.5s;
  width: 40%;
  left: 0;
}
.bg_tx {
  padding: 5px;
  background: #fff;
}
.min_btm {
  margin-bottom: -18px;
}
@keyframes rightmove {
  0% {
    left: 0;
  }
  50% {
    left: 30%;
    width: 60%;
  }
  100% {
    left: 60%;
  }
}
@keyframes leftmove {
  0% {
    left: 60%;
  }
  50% {
    left: 10%;
    width: 60%;
  }
  100% {
    left: 0;
  }
}

@media (max-width: 768px) {
  .switch {
    display: block;
  }
  .enable {
    padding: 10px;
    width: 100%;
    transition: 0.5s;
  }
  .disable {
    display: none;
    transition: 0.5s;
  }
  .temp-form {
    width: 100%;
  }
  .right {
    display: none !important;
  }
  .left {
    display: none !important;
  }
}

@media (max-width: 576px) {
  .switch {
    display: block;
  }
  .enable {
    padding: 10px;
    width: 100%;
    transition: 0.5s;
  }
  .disable {
    display: none;
    transition: 0.5s;
  }
  .temp-form {
    width: 100%;
  }
  .right {
    display: none !important;
  }
  .left {
    display: none !important;
  }
}
</style>
