<template>
  <v-row justify="start">
    <v-dialog v-model="dialogResend" scrollable persistent max-width="500px">
      <v-card class="radius-card">
        <v-overlay :absolute="true" :value="loading">
          <v-progress-circular
            indeterminate
            color="purple"
          ></v-progress-circular>
        </v-overlay>
        <v-toolbar dense flat>
          <span class="headline">Masukan email yang terdaftar</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="closeDialog"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="px-2">
          <v-container v-if="!attr">
            <v-text-field
              v-model="email"
              label="Email"
              solo
              background-color="#F3E5F5"
              flat
            ></v-text-field>
            <section class="d-flex justify-end">
              <v-btn color="purple" dark @click="send">Send</v-btn>
            </section>
          </v-container>
          <v-container v-if="attr">
            <section class="d-flex align-center flex-column">
              <v-btn color="purple" dark fab depressed large
                ><v-icon>mdi-email</v-icon></v-btn
              >
              <div>
                <section class="text-center">
                  <h1 class="green--text">Success!</h1>
                  <p>please check your inbox</p>
                </section>
              </div>
            </section>
          </v-container>
        </v-card-text>
        <notifications group="validate" class="mt-2" position="top center" />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { maxLength } from "vuelidate/lib/validators";

export default {
  name: "editAbout",
  mixins: [validationMixin],
  validations: {
    about: { maxLength: maxLength(225) }
  },
  props: ["dialogResend"],

  data() {
    return {
      loading: false,
      email: "",
      attr: false
    };
  },

  methods: {
    send() {
      let data = {
        email: this.email
      };
      this.loading = true;
      this.$store
        .dispatch("auth/resend", data)
        .then(data => {
          this.loading = false;
          if (data.status == "success") {
            this.email = "";
            this.attr = true;
          } else {
            this.alert("error", data.message);
          }
        })
        .catch(err => {
          this.loading = false;
          this.alert("error", err.data.message);
        });
    },
    closeDialog() {
      this.attr = false;
      this.$emit("close");
    },
    alert(type, title) {
      this.$notify({
        group: "validate",
        type: type,
        title: title,
        duration: 5000
      });
    }
  }
};
</script>

<style scoped>
.add-width {
  width: 170px;
}
.temp {
  position: relative;
}
.over {
  position: absolute;
  margin-top: -5px;
  margin-right: -5px;
}
.bg {
  background: #ffff;
}
</style>
