<template>
  <div>
    <notifications
      group="authVerification"
      class="mt-2"
      position="top center"
    />
    <v-expand-transition>
      <v-alert type="error" dense class="mb-1" v-if="errMsg">
        {{ errMsg.message }}
      </v-alert>
    </v-expand-transition>
    <div class="pa-2 mb-5">
      <form @submit.prevent="login">
        <v-text-field
          v-model="name"
          counter
          :error-messages="UsernameError"
          solo
          background-color="#F3E5F5"
          flat
          label="Username"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        ></v-text-field>
        <v-text-field
          v-model="password"
          label="Password"
          :error-messages="PasswordError"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          counter
          solo
          background-color="#F3E5F5"
          flat
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
          @click:append="show = !show"
        ></v-text-field>
        <div class="__min_top">
          <small>Password harus di ketik, tidak boleh copy paste!</small>
        </div>
        <div class="button-group mt-5 d-flex flex-column">
          <v-btn class="mb-3" type="login" v-if="!loading" color="purple" dark>
            <!-- <span v-if="loading" class="d-flex"> Silakan tunggu ... </span> -->
            <b> MASUK </b>
          </v-btn>
          <v-btn class="mb-1" v-if="loading" color="purple" dark>
            <!-- <span v-if="loading" class="d-flex"> Silakan tunggu ... </span> -->
            <b> Silakan Tunggu...</b>
          </v-btn>
          <!-- <small class="forgot-pwd" @click="dialog = true"
          >Lupa kata sandi?</small
        > -->
          <v-btn text @click="dialogResend = true">
            <p class="ma-0 forgot-pwd">Lupa kata sandi?</p>
          </v-btn>
        </div>
        <ResendEmail v-bind:dialogResend="dialogResend" @close="closeDialog" />
      </form>
    </div>
    <v-divider></v-divider>
    <div class="mt-5 d-flex justify-center">
      <v-btn color="white" @click="checkAuth">
        <img
          src="../../assets/img/google-logo.png"
          class="mr-2"
          height="20px"
          alt=""
        />
        Sign in with google</v-btn
      >
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import ResendEmail from "./resendEmail.vue";
export default {
  components: { ResendEmail },
  name: "Login",
  mixins: [validationMixin],
  validations: {
    name: { required },
    password: { required, minLength: minLength(8) },
  },
  data() {
    return {
      name: "",
      password: "",
      show: false,
      dialogResend: false,

      errAlert: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      result: (state) => state.auth.solveMsg,
      errMsg: (state) => state.auth.errMsg,
      token: (state) => state.token,
    }),
    UsernameError() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Nama Pengguna harus di isi!");
      return errors;
    },
    PasswordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Kata sandi minimal 8 karakter!");
      !this.$v.password.required && errors.push("Kata sandi harus di isi!");
      return errors;
    },
  },
  methods: {
    async checkAuth() {
      const user = await this.$gAuth.signIn();
      const profile = user.getBasicProfile();
      const email = profile.getEmail();
      this.$store.dispatch("auth/googleSign", { email: email }).then(() => {
        this.$router.go();
      });
    },
    login() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        this.$store
          .dispatch("auth/login", {
            username: this.name,
            password: this.password,
          })
          .then(() => {
            if (this.result.status == "success") {
              this.alert("success", this.result.message);
              if (this.result.data.user.role_id == 1) {
                this.$router.go("/admin/dashboard");
              } else if (this.result.data.user.role_id == 2) {
                this.$router.go("/admin/dashboard");
              } else if (this.result.data.user.role_id == 3) {
                if (this.result.data.user.status == 1) {
                  this.$router.go("/psikolog/forum");
                } else {
                  this.$router.go("/psikolog/fill-data");
                }
              } else {
                this.$router.go("/public/home");
              }
            } else {
              this.loading = false;
              this.alert("error", `${this.result.message}`);
              this.password = "";
              this.$v.$reset();
              let sec = 10;
              let min = setInterval(() => {
                sec--;
                this.counting = sec;
                if (sec === 0) {
                  this.$store.commit("auth/RESET_MSG", "");
                  clearInterval(min);
                }
              }, 1000);
            }
            console.log(this.result);
          })
          .catch((e) => {
            console.log(e);
            // this.alert("error", `${this.errMsg.message}`);
            this.loading = false;
            this.password = "";
            this.$v.$reset();
            let sec = 5;
            let min = setInterval(() => {
              sec--;
              this.counting = sec;
              if (sec === 0) {
                this.$store.commit("auth/RESET_MSG", "");
                clearInterval(min);
              }
            }, 1000);
          });
      }
    },
    closeDialog() {
      this.dialogResend = false;
    },
    alert(type, title, msg) {
      this.$notify({
        group: "authVerification",
        type: type,
        title: title,
        text: msg,
      });
    },
  },
};
</script>

<style scoped>
.__min_top {
  margin-top: -10px;
}
</style>
